//
// vector-maps.scss
//

.jvectormap-label {
    border: none;
    background: var(--#{$variable-prefix}gray-800);
    color: var(--#{$variable-prefix}gray-100);
    font-family: $font-family-base;
    font-size: $font-size-base;
    padding: 5px 8px;
}